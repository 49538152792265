.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #2B3947;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #50E3C2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Binance Tools CSS */

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
}

.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: inherit;
}

.ant-menu-item > a:hover {
    color: rgba(0,0,0,0.65);
}

div.ant-row.ant-form-item.form-100 > div {
  width: 98%
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.header-container {
  background: #2B3947;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.09);
  z-index: 10;
  position: relative;
}

.button {
  background: #F0B90B;
  border-radius: 9px;
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 2.75px;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
}

.pill {
  background: #EDEDED;
  border-radius: 28px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #848E9C;
  letter-spacing: 0;
}

.text-box-area {
  border: 1px solid #F0B90B;
  border-radius: 6px;
}

.text-box-input {
  background: #F8F8F8;
  border: 1px solid #848E9C;
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #848E9C;
  letter-spacing: 0;
  line-height: 23px;
}

.h1 {
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 2.75px;
  font-size: 42px;
  color: #FFFFFF;
}

.text {
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 2.75px;
  font-size: 18px;
  color: #FFFFFF;
}

.text-bold {
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 2.75px;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
}

.text-bg {
  background: #FFFFFF;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #50E3C2;
}

.test.ant-btn {
  font-size: 100; 
}


/*============================*/
